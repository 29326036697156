<template>
  <fragment>
    <add-edit-page>
      <template #header="{}">
        <sticky-header title="Review Settlement">
          <div class="sticky-flex-container">
            <div class="sticky-header-main">
              <h4>{{ clientName }}</h4>
              <div class="button-group">
                <a class="button secondary" @click="$router.go(-1)" @click.prevent>Cancel</a>
              </div>
            </div>
            <div class="sticky-header-aside">
              <div class="stats">
                <div>
                  <h5 class="stat-header">Pay Period</h5>
                  <div class="stat">{{ payPeriod }}</div>
                </div>
                <div>
                  <h5 class="stat-header">Deposit Summaries</h5>
                  <div class="stat">{{ globalCount | formatNumber }}</div>
                </div>
                <div>
                  <h5 class="stat-header text-right">Amount Invoiced</h5>
                  <div class="stat right">{{ totalInvoiced | formatMoney("s") }}</div>
                </div>
              </div>
            </div>
          </div>
        </sticky-header>
      </template>
      <!-- multiple invoices -->
      <div class="module-xlarge">
        <h3>INVOICES</h3>
        <data-table :entries="multipleInvoices" :headers="invoiceHeaders" min-height="300px">
          <template #invoice="{ item }">
            <a @click="viewInvoice(item.id, item.status)">
              {{ item.invoice }} <span class="label-inline">{{ invoiceApprovalStatus(item.status) }}</span>
            </a>
          </template>
          <template #invoiceNumber="{ item }"> {{ item.invoiceNumber }}</template>
          <template #payPeriod="{ item }"> {{ item.payPeriod }}</template>
          <template #amountOnInvoice="{ item }"> {{ item.amountOnInvoice | formatMoney }}</template>
        </data-table>
      </div>
      <!-- client revenue items -->
      <div class="module-xlarge">
        <h3>CLIENT REVENUE ITEMS</h3>
        <data-table :entries="clientRevenue" :headers="clientRevenueHeaders" :loading="clientRevenueLoader"
          min-height="300px">
          <template #name="{ item }"> {{ item.name }}</template>
          <template #billedToClient="{ item }"> {{ item.amount | formatMoney }}</template>
        </data-table>
      </div>
      <div class="module-xlarge">
        <div class="flex">
          <div class="cell70 inline-offset">
            <h3>DEPOSIT SUMMARIES ({{ totalCount | formatNumber }} Partner{{ totalCount === 1 ? "" : "s" }}) {{
            displayGarnishCount }}</h3>
          </div>
          <div class="cell30 text-right">
            <span v-if="csvLoader" class="loading">
              <Loader style="margin-top: 10px" />
            </span>
            <meatball :id="`${settlementID}`" right title="Options" class="padding-top-12">
              <menu-item v-if="!csvLoader" label="Deposit Summary CSV" :on-click="fetchAllSummaries" />
              <!-- <menu-item v-if="!csvLoader" label="Legacy Export" :on-click="fetchLegacyExport" /> -->
              <menu-item v-if="!csvLoader" label="Raw Transaction File" :on-click="rawTransactionFile" />
            </meatball>
          </div>
        </div>
        <div class="flex">
          <div class="cell70">
            <base-filter name="Filter Group" :options="paymentOptions" @onFilter="fetchFilteredCandidates" />
          </div>
          <div style="margin-left: 210px;" v-if="totalCount !== -1" class="cell100 acc-page paddingT24">
            <paginator :total="totalCount" :per-page="perPage" :sync-page="currentPage"
              @onPaginate="fetchData($event)" />
          </div>
        </div>
        <div>
          <div class="search-container">
            <input v-model="searchTerm" @input="partnerSearch($event)" class="deposit-search" type="search" placeholder="Search Partner Name" />
            <button type="submit"><span v-if="searchIcon" class="icon-x deposit-icon" @click="resetSearch"></span> <span
                v-else class="icon-search deposit-icon"></span></button>
          </div>
        </div>
        <data-table :entries="depositSummaries" :headers="settlementHeaders" :loading="depositLoader">
          <template #name="{ item }">
            {{ tableContents(item, "name") }} <em class="label-inline label-space">{{ garnishment(item.garnishment)
            }}</em></template>
          <template #grossAmount="{ item }"> {{ tableContents(item.check["BUSINESS_INCOME"], "amount") | formatMoney
          }}</template>
          <template #credits="{ item }"> {{ tableContents(item.check["CREDITS"], "amount") | formatMoney }}</template>
          <template #debits="{ item }"> {{ tableContents(item.check["DEBITS"], "amount") | formatMoney }}</template>
          <template #withholdings="{ item }"> {{ tableContents(item.check["WITHHOLDING"], "amount") | formatMoney
          }}</template>
          <template #netAmount="{ item }"> {{ tableContents(item.check["NET"], "amount") | formatMoney }}</template>
          <!-- <template #actions="{ item }"><a @click="openFlyout(item)" @click.prevent>Details</a></template> -->
          <template #actions="{ item }">
            <meatball :id="`${item}`" right title="Details" class="padding-top-12" @selected="currentElement(item)">
              <menu-item label="View Deposit Summary" :on-click="openFlyout" />
              <menu-item v-if="item.status !== 'PAYMENT_HELD' && item.status !== 'PAID'" label="Hold Payment"
                :on-click="holdPayment" />
              <menu-item v-if="item.status === 'PAYMENT_HELD' && item.status !== 'PAID'" label="Remove Hold"
                :on-click="removeholdPayment" />
            </meatball>
          </template>
        </data-table>
        <div v-if="totalCount !== -1" class="cell100 acc-page paddingT24">
          <paginator :total="totalCount" :per-page="perPage" :sync-page="currentPage" @onPaginate="fetchData($event)" />
        </div>
      </div>
      <deposit-summary-flyout v-if="loadFlyout" name="deposit-summary" :details="depositSummaryFlyout">
      </deposit-summary-flyout>
    </add-edit-page>
    <invoice-modal invoice-modal-name="accountingInvoice" :invoice-id="selectedInvoice" :status="status" />
  </fragment>
</template>

<script>
import { AddEditPage, StickyHeader, Paginator, Loader, MenuItem, Meatball, BaseFilter } from "@/components";
// import { GET_DEPOSIT_SUMMARIES_FOR_LEGACY } from "@/modules/admin/accounting/invoicing/queries";
// import { CHANGE_DEPOSIT_SUMMARY_STATUS } from "@/modules/admin/accounting/invoicing/mutations";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import micromodal from "micromodal";
import { mapGetters, mapActions } from "vuex";
import DepositSummaryFlyout from "@/modules/admin/accounting/invoicing/DepositSummaryFlyout";
import { Fragment } from "vue-fragment";
import InvoiceModal from "@/modules/admin/accounting/invoicing/InvoiceModal";
import { formatDatePacific, formatNumber, formatDate } from "@/filters";
import debounce from "debounce";
import { restApi } from "../../../../http/http.config";
import { encodeWithParam } from "../../../../util/Base64Encoding";

const perPage = 50;
export default {
  components: { DataTable, StickyHeader, AddEditPage, DepositSummaryFlyout, Paginator, Fragment, InvoiceModal, Loader, Meatball, MenuItem, BaseFilter },
  data: () => ({
    settlement: {
      completeEntries: [],
      location: {},
    },
    garnishCount: 0,
    depositLoader: true,
    clientRevenueLoader: true,
    settlementDetails: {},
    depositSummaries: [],
    searchTerm: "",
    searchArray: [],
    totalCount: 0,
    globalCount: 0,
    currentPage: 0,
    selectedInvoice: "",
    depositSummaryFlyout: {},
    depositSummaryCsv: null,
    clientRevenue: [],
    multipleInvoices: [],
    totalInvoiced: 0,
    payPeriod: "",
    status: "",
    targetElement: {},
    csvLoader: false,
    depositSummaryStatuses: ["PAID", "PENDING", "PAYMENT FAILURE", "PENDING_GARNISHMENT", "READY_FOR_DISTRIBUTION", "PAYBACK_FAILED", "CREATED", "CANCELLED", "ON_HOLD", "PAYMENT_HELD"],
  }),
  computed: {
    ...mapGetters(["getUsername"]),
    settlementID() {
      return this.$route.params.settlementID;
    },
    clientName() {
      return this.$route.params.client;
    },
    totalPayout() {
      return this.totalInvoiced - this.settlementDetails.withholding;
    },
    displayGarnishCount() {
      if (this.garnishCount === 0) {
        return "";
      } else {
        return `(${formatNumber(this.garnishCount)} WITHHELD)`;
      }
    },
    settlementHeaders() {
      return [
        new TableHeader({ label: "Partner Name", headerClass: "cell30", name: "name", cellContents: "name", cellClass: "link-color" }),
        new TableHeader({ label: "Business Income", headerClass: "cell15 text-right", name: "grossAmount", cellContents: "grossAmount" }),
        new TableHeader({ label: "Credits", headerClass: "cell15 text-right", name: "credits", cellContents: "credits" }),
        new TableHeader({ label: "Debits", headerClass: "cell10 text-right", name: "debits", cellContents: "debits" }),
        new TableHeader({ label: "Other Withholdings", headerClass: "cell15 text-right", name: "withholdings", cellContents: "withholdings" }),
        new TableHeader({ label: "(Net) Total Earnings", headerClass: "cell20 text-right", name: "netAmount", cellContents: "netAmount" }),
        new TableHeader({ cellContents: "actions", headerClass: "cell10 text-right", name: "actions", cellClass: "table-actions" }),
      ];
    },
    clientRevenueHeaders() {
      return [new TableHeader({ label: "Name", headerClass: "cell50 left", name: "name" }), new TableHeader({ label: "Billed to Client", headerClass: "right", name: "billedToClient" })];
    },
    invoiceHeaders() {
      return [
        new TableHeader({ label: "Invoice", headerClass: "cell25 left", name: "invoice" }),
        new TableHeader({ label: "Invoice Number", headerClass: "cell25 left", name: "invoiceNumber" }),
        new TableHeader({ label: "Pay Period", headerClass: "cell30 left", name: "payPeriod" }),
        new TableHeader({ label: "Amount on Invoice", headerClass: "right", name: "amountOnInvoice" }),
      ];
    },
    searchIcon() {
      return this.searchTerm !== "";
    },
    perPage() {
      return perPage;
    },
    loadFlyout() {
      return Object.keys(this.depositSummaryFlyout).length > 0 ? true : false;
    },
    csvHeaders() {
      return ["Client", "Client ID", "Partner ID", "Name", "Business Income", "Credits", "Debits", "Withholding", "Net"];
    },
    paymentOptions() {
      return [
        { id: "PAID", name: "Paid", label: "Paid" },
        { id: "WITHHELD", name: "Withheld", label: "Withheld" },
        { id: "FAILED", name: "Failed", label: "Failed" },
        { id: "PENDING", name: "Pending", label: "Pending" },
        { id: "PAYMENT_HELD", name: "Hold Payment", label: "Hold Payment" },
      ];
    },
    defaultStatuses() {
      return ["PAID", "PENDING", "PAYMENT FAILURE", "PENDING_GARNISHMENT", "READY_FOR_DISTRIBUTION", "PAYBACK_FAILED", "CREATED", "CANCELLED", "ON_HOLD", "PAYMENT_HELD"];
    },
    isUUID() {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/g;
      return this.searchTerm.match(regex);
    },
  },
  watch: {
    searchTerm() {
      if (this.searchTerm == "") {
         this.getDepositSummaries(this.depositSummaryStatuses, 50, 0);
  //       this.partnerIDSearch(this.depositSummaryStatuses);
      } 
  // else if (this.searchTerm && this.globalCount <= perPage) {
  //       let regex = new RegExp(this.searchTerm, "i");
  //       this.depositSummaries = this.searchArray.filter((item) => {
  //         return regex.test(`${item.partner.firstName} ${item.partner.lastName}`);
  //       });
  //       this.totalCount = this.depositSummaries.length;
  //     } else if (this.searchTerm && this.globalCount > perPage) {
  //       this.searchQuery(50, 0, this.depositSummaryStatuses);
  //     } else {
  //       this.depositLoader = true;
  //       this.getDepositSummaries(this.depositSummaryStatuses, 2,0).then(() => {
  //         this.depositLoader = false;
  //       });
  //     }
  //     this.currentPage = 1;
    },
  },
  mounted() {
    this.getClientRevenue()
    this.getDepositSummaries(this.depositSummaryStatuses, 50, 0)
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
    this.searchQuery = debounce(this.searchQuery, 500);

    this.getSettlements();
  },
  methods: {
    ...mapActions(["showFlyout"]),
    invoiceApprovalStatus(status) {
      return status === "APPROVED" ? "(Approved)" : "";
    },

    partnerSearch(event){
      let nameSearch = ''
      let idSearch = ''
      if(!this.isUUID){
        idSearch = '00000000-0000-0000-0000-000000000000'
        nameSearch = `%${event.target.value}%`
      }
      if(this.isUUID){
        idSearch = event.target.value
        nameSearch = ''
      }
       this.depositLoader = true;
      restApi.post('accounting/searchPartnersDepositSummariesByID', encodeWithParam({ id: this.settlementID, status: this.depositSummaryStatuses, partnerID: idSearch, partnerName: nameSearch })).then((data) => {
        // const res = data.data.result[0]
        this.depositSummaries = data.data.result
        // this.totalCount = this.globalCount = res.length;
        this.depositLoader = false;
      }).catch((err) => console.log(err))
    },
    getTransations() {
      restApi.post('accounting/getTransactions', encodeWithParam({ id: this.settlementID })).then((data) => {
        const res = data.data.result[0]
        let trascationFile = [];
        this.csvLoader = true;
        res.forEach((deposites) => {
          deposites.settlement.transactions.forEach((item) => {
            let customObj = {
              // "StoreID": "",
              MarketName: item?.partner?.locations[0]?.location?.name ? item.partner.locations[0].location.name : "",
              // "OrderID": "",
              DriverName: `${item?.partner?.firstName ? item.partner.firstName : ""} ${item?.partner?.lastName ? item.partner.lastName : ""}`,
              WM_Payee_ID: item?.partner?.locations[0]?.location?.externalID ? item.partner.locations[0].location.externalID : "",
              PayoutID: item?.payoutID ? item.payoutID : "",
              TransactionID: item?.externalID ? item.externalID : "",
              Description: item?.memo ? item.memo : "",
              // "DeliveryFeeByCustomer": "",
              // "DeliveryFeeWMReward": "",
              // "BrokerageFeeToWM":"",
              // "Tips":"",
              Amount: item?.amount ? item.amount : "",
              RequestDate: formatDatePacific(item?.date ? item.date : ""),
              CreatedOn: formatDatePacific(item?.createdDate ? item.createdDate : ""),
              Type: item?.settlementItem?.mappingKey ? item.settlementItem.mappingKey : "",
              DEmail: item?.partner?.email ? item.partner.email : "",
              PDLogID: item?.partner?.ids?.externalID ? item.partner.ids.externalID : "",
              partnerID: item?.partner?.id ? item.partner.id : "",
            };
            trascationFile.push(customObj);
          });
        });
        let downloadObj = this.$papa.unparse(trascationFile, {});
        this.$papa.download(downloadObj, "Transaction_File");
        this.csvLoader = false;

      }).catch((e) => { console.log(e) })
    },
    openFlyout() {
      this.targetElement["payPeriod"] = this.payPeriod;
      this.depositSummaryFlyout = this.targetElement;
      this.showFlyout("deposit-summary");
    },
    garnishment(value) {
      if (value) {
        return "Withheld";
      } else {
        return "";
      }
    },
    tableContents(content, value) {
      switch (value) {
        case "name":
          return `${this.nullCheck(content.partner.firstName) ? content.partner.firstName : ""} ${this.nullCheck(content.partner.lastName) ? content.partner.lastName : ""}`;
        case "amount":
          return content ? content["amount"] : 0;
      }
    },
    depositCsv(data) {
      const csvData = [];
      let headers = new Set();
      data.map((item) => {
        const obj = {};
        const map = new Map();
        const {check} = item;
        obj["Status"] = `${item.status}`;
        obj["Client"] = `${process.env.VUE_APP_CLIENT_NAME}`;
        obj["Client ID"] = `${process.env.VUE_APP_CLIENT_ID}`;
        obj["Partner ID"] = item.partnerID;
        obj["Name"] = `${item.fullName}`;
        obj["Email"] = `${item.email}`;
        obj["Transaction ID"] = `${item.depositSummarieID}`;
        obj[`${check["BUSINESS_INCOME"]?.name}`] = `${check["BUSINESS_INCOME"]?.amount}`;
        obj[`${check["CREDITS"]?.name}`] = `${check["CREDITS"]?.amount}`;
        obj[`${check["DEBITS"]?.name}`] = `${check["DEBITS"]?.amount}`;
        obj[`${check["WITHHOLDING"]?.name}`] = `${check["WITHHOLDING"]?.amount}`;
        obj[`${check["NET"]?.name}`] = `${check["NET"]?.amount}`;
        for (const settlement in check) {
          check[`${settlement}`]?.items?.forEach((element) => {
            map.has(element.name) ? map.set(element.name, element.amount + map.get(element.name)) : map.set(element.name, element.amount);
          });
        }
        for (const [key, value] of map.entries()) {
          obj[`${key}`] = value;
        }
        obj["ServicesRendered"] = check["BUSINESS_INCOME"]?.items?.reduce((sum, obj) => {
          if (obj.servicesRendered > 0) {
            sum = sum + obj.servicesRendered;
          }
          return sum;
        }, 0);
        csvData.push(obj);
        this.parseHeaders(obj, headers);
      });
      if (headers.size == 0) {
        headers = this.csvHeaders;
      }
      this.depositSummaryCsv = this.$papa.unparse(csvData, {
        delimiter: ",",
        columns: Array.from(headers),
      });
      this.$papa.download(this.depositSummaryCsv, "Deposit_Summaries");
      this.csvLoader = false;
    },
    parseHeaders(data, uniqueSet) {
      for (const key in data) {
        uniqueSet.add(key);
      }
      return uniqueSet;
    },
    nullCheck(item) {
      return item != null && item != "" && item != "NULL";
    },
    fetchData(event) {
      this.currentPage = Number(event.pageNumber);
      if (this.searchTerm) {
        this.searchQuery(Number(event.limit), Number(event.offset), this.depositSummaryStatuses);
      } else {
        this.depositLoader = true;
        console.log(event.limit, event.offset)
        this.getDepositSummaries(this.depositSummaryStatuses, Number(event.limit), Number(event.offset))
      }
    },
    fetchAllSummaries() {
      // GET_DEPOSIT_SUMMARIES_FOR_ALL
      this.csvLoader = true;
      restApi.post('accounting/getDepositSummariesForAll', encodeWithParam({ settlementID: this.settlementID })).then((data) => {
        const res = data.data;
        this.depositCsv(res);
      }).catch((e) => { console.log(e) })
    },
    fetchClientRevenueItems(items) {
      const map = new Map();
      const revenueArr = [];
      items.forEach((item) => {
        map.has(item.memo) ? map.set(item.memo, Number(item.amount) + map.get(item.memo)) : map.set(item.memo, Number(item.amount));
      });
      for (const [key, value] of map.entries()) {
        revenueArr.push({ name: `${key}`, amount: `${value}` });
      }
      this.clientRevenue = revenueArr;
    },
    viewInvoice(id, status) {
      this.selectedInvoice = id;
      this.status = status;
      micromodal.show("accountingInvoice");
      document.body.style.overflow = "hidden";
    },
    resetSearch() {
      this.searchTerm = "";
      this.currentPage = 1;
      this.getDepositSummaries(this.depositSummaryStatuses, 50, 0);
    },
    sum(lineItems) {
      return lineItems.reduce((acc, curr) => {
        const num = parseFloat(curr.amount);
        return acc + num;
      }, 0);
    },
    // fetchLegacyExport() {
    //   this.csvLoader = true;
    //   this.$apollo
    //     .query({
    //       query: GET_DEPOSIT_SUMMARIES_FOR_LEGACY,
    //       variables: { id: this.settlementID },
    //     })
    //     .then(async ({ data }) => {
    //       try {
    //         await this.legacyExports(data.depositSummaries);
    //       } catch (error) {
    //         this.csvLoader = false;
    //       }
    //     });
    // },
    // legacyExports(data) {
    //   const csvData = [];
    //   let headers = ["", "Date", "Name", "E-Mail", "Memo", "Original Amount"];
    //   const months = ["", "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    //   const { sd, ed } = { ed: this.payPeriod.split("-")[1].substring(1), sd: this.payPeriod.split("-")[0] };
    //   const endDate = `${months.indexOf(ed.split(" ")[1].toLowerCase())}/${ed.split(" ")[0]}/${ed.split(" ")[2]}`;
    //   const startDate = `${months.indexOf(sd.split(" ")[1].toLowerCase())}/${sd.split(" ")[0]}/${sd.split(" ")[2]}`;
    //   data.map(async (item) => {
    //     const map = new Map();
    //     const { partner, check } = item;
    //     for (const settlement in check) {
    //       check[`${settlement}`]?.items?.forEach((element) => {
    //         if (settlement == "DEBITS" || settlement == "WITHHOLDING") {
    //           map.has(element.name) ? map.set(element.name, `-${element.amount + map.get(element.name)}`) : map.set(element.name, `-${element.amount}`);
    //         } else {
    //           map.has(element.name) ? map.set(element.name, element.amount + map.get(element.name)) : map.set(element.name, element.amount);
    //         }
    //       });
    //     }

    //     for (let i = 1; i <= 5; i++) {
    //       const obje = {};
    //       if (i == 1 || i == 3) {
    //         this.spaceFields(obje, csvData);
    //       } else if (i == 2) {
    //         obje["Date"] = `${endDate}`;
    //         obje["Name"] = `${partner?.firstName} ${partner?.lastName}`;
    //         obje["E-Mail"] = `${partner?.email}`;
    //         obje["Memo"] = `Deliveries: ${item.servicesRendered} From ${startDate} - ${endDate}`;
    //         obje["Original Amount"] = `-${check["NET"]?.amount}`;
    //         csvData.push(obje);
    //       } else if (i == 5) {
    //         obje[""] = "TOTAL";
    //         obje["Original Amount"] = `${check["NET"]?.amount}`;
    //         csvData.push(obje);
    //       } else {
    //         for (const [key, value] of map.entries()) {
    //           const obj = {};
    //           obj["Memo"] = key;
    //           obj["Original Amount"] = value;
    //           csvData.push(obj);
    //         }
    //       }
    //     }
    //   });
    //   this.depositSummaryCsv = this.$papa.unparse(csvData, {
    //     delimiter: ",",
    //     columns: Array.from(headers),
    //   });
    //   this.$papa.download(this.depositSummaryCsv, "Legacy_Reports");
    //   this.csvLoader = false;
    // },
    spaceFields(obje, csvData) {
      obje["Date"] = "";
      obje["Name"] = "";
      obje["E-Mail"] = "";
      obje["Memo"] = "";
      obje["Original Amount"] = "";
      return csvData.push(obje);
    },
    calculateDeliveries(check) {
      return check["BUSINESS_INCOME"]?.items?.reduce((sum, obj) => {
        if (obj.servicesRendered === null) {
          obj.servicesRendered = 1;
        }
        if (obj.servicesRendered > 0) {
          sum = sum + obj.servicesRendered;
        }
        return sum;
      }, 0);
    },
    fetchFilteredCandidates(event) {
      const status = [];
      event.map((element) => {
        switch (element) {
          case "paid":
            status.push("PAID");
            break;
          case "failed":
            status.push("PAYMENT FAILURE", "PAYBACK_FAILED", "CANCELLED");
            break;
          case "withheld":
            status.push("PENDING_GARNISHMENT", "ON_HOLD");
            break;
          case "pending":
            status.push("PENDING", "READY_FOR_DISTRIBUTION", "CREATED");
            break;
          case "hold payment":
            status.push("PAYMENT_HELD");
            break;
        }
      });
      this.depositSummaryStatuses = status.length != 0 ? status : this.defaultStatuses;
      if (this.searchTerm) {
        this.searchQuery(50, 0, this.depositSummaryStatuses);
      } else {
        this.getDepositSummaries(this.depositSummaryStatuses, 50, 0);
        this.currentPage = 1;
      }
    },
    searchQuery(limit, offset, status) {
      this.depositLoader = true;
      this.getDepositSummaries(status, limit, offset)
    },
    // partnerIDSearch(status) {
    //   this.depositLoader = true;
    //   restApi.post('accounting/searchPartnersDepositSummariesByID', encodeWithParam({ id: this.settlementID, status: status, partnerID: this.searchTerm })).then((data) => {
    //     const res = data.data.result[0]
    //     this.depositSummaries = data.summaries;
    //     this.totalCount = this.globalCount = res.length;
    //     this.depositLoader = false;
    //   }).catch((err) => console.log(err))
    // },
    currentElement(elementId) {
      this.targetElement = elementId;
    },

    holdPayment() {
      this.changeDepositSummaryStatus(this.targetElement.id, "PAYMENT_HELD");
    },
    removeholdPayment() {
      this.changeDepositSummaryStatus(this.targetElement.id, "READY_FOR_DISTRIBUTION");
    },
    changeDepositSummaryStatus(id, status) {
      restApi.post('accounting/updateDespositSummaryStatus', encodeWithParam({ id: id,status: status })).then(() => {
        this.getDepositSummaries(this.depositSummaryStatuses, 50, 0)
      })
    },
    getSettlements() {
      restApi.post('accounting/getSettlements', encodeWithParam({ id: this.settlementID })).then((data) => {
        const res = data.data.result[0]
        let invoiceObj = res.invoices;
        let debitOrCredit = { credits: res.credits, debits: res.debits };

        if (invoiceObj.length > 0) {
          this.payPeriod = `${formatDate(res.startDate)} - ${formatDatePacific(res.endDate)}`;
          invoiceObj.forEach((item) => {
            const lineItems = item?.invoice?.lineItems ? item.invoice.lineItems : [];
            const amountOnInvoice = this.sum(lineItems);
            this.totalInvoiced += amountOnInvoice;
            let customObj = {
              invoice: item.invoice.name,
              invoiceNumber: item.invoiceNumber,
              totalGross: item?.invoice?.lineItems[0]?.amount ? item?.invoice?.lineItems[0]?.amount : Number(0),
              totalCredits: debitOrCredit.credits,
              totaldebits: debitOrCredit.debits,
              amountOnInvoice,
              id: item.invoice.id,
              payPeriod: `${formatDate(item.settlement.startDate)} - ${formatDatePacific(item.settlement.endDate)}`,
              status: item.status,
            };
            this.multipleInvoices.push(customObj);
          }, this);
        }

      }).catch((e) => { console.log(e) })
    },
    getDepositSummaries(status, limit, offset) {
      restApi.post('accounting/getDepositSummaries', encodeWithParam({ id: this.settlementID, status: status, limit: limit, offset: offset })).then((data) => {
        const res = data.data.result
        this.depositSummaries = this.searchArray = res;
        this.totalCount = this.globalCount = Number(data.data.count[0].count);
        this.depositLoader = false;
        this.garnishCount = res.reduce((acc, curr) => {
          return curr.garnishment ? (acc += 1) : acc;
        }, 0);
      }).catch((e) => { console.log(e) })
    },

    downloadFile(data, fileName){
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const blob = new Blob([data], {type: "octet/stream"}),
      url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    
    rawTransactionFile() {
      this.csvLoader = true;
      restApi.post('accounting/getRawTransactions', encodeWithParam({ settlementID: this.$route.params.settlementID })).then((data) => {
        if(data.data){
          this.downloadFile(data.data,"Transaction_File.csv")
          this.csvLoader = false;
        }
      })
    },

    getClientRevenue() {
      restApi.post('accounting/getClientRevenue', encodeWithParam({ id: this.settlementID })).then((data) => {
        const res = data.data.result
        this.fetchClientRevenueItems(res);
        this.clientRevenueLoader = false;
      }).catch((e) => { console.log(e) })
    }
  },
};
</script>